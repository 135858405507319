<template>
  <div class="self-center">
    <div class="flex-row flex-wrap">
      <!-- View -->
      <b-button
        v-b-tooltip.hover.top="'Click here to view Applications'"
        class="btn-icon small-space"
        variant="outline-dark"
        @click="viewRecord"
      >
        <feather-icon
          icon="EyeIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <!-- Edit -->
      <b-button
        v-if="$checkRights(this.$route.meta.section, 'update')"
        v-b-tooltip.hover.top="'Edit this job'"
        class="btn-icon small-space"
        variant="outline-dark"
        @click="editRecord"
      >
        <feather-icon
          class="text-warning"
          icon="EditIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <!-- Deactivate -->
      <b-button
        v-if="$checkRights(this.$route.meta.section, 'delete') && params.account_approved"
        v-b-tooltip.hover.top="'Deactivate this user'"
        class="btn-icon small-space"
        variant="outline-dark"
        @click="confirmDeactivateRecord"
      >
        <feather-icon
          class="text-danger"
          icon="Trash2Icon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>
      <!-- Reactivate -->
      <b-button
        v-else-if="$checkRights(this.$route.meta.section, 'delete') && !params.account_approved"
        v-b-tooltip.hover.top="'Reactivate this user'"
        class="btn-icon small-space"
        variant="outline-dark"
        @click="confirmReactivateRecord"
      >
        <feather-icon
          class="text-success"
          icon="RotateCcwIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CellRendererActions',

  components: {
    //
  },
  props: {
    params: Object,
  },
  data() {
    return {
      isLoading: false,
    }
  },

  methods: {
    viewRecord() {
      this.$router.push(`/applicant-tracking/applications/${this.params.id}`)
    },

    editRecord() {
      this.$router.push({ name: 'user-manager-edit', params: { id: this.params.id } })
    },

    invokeParentMethod(popup, edit_data) {
      this.params.context.componentParent.methodFromParent(popup, edit_data)
    },

    confirmDeactivateRecord() {
      if (this.$checkRights(this.$route.meta.section, 'update')) {
        this.$bvModal
          .msgBoxConfirm(
            `Are you sure you want to deactivate "${this.params.email_address}"`,
            {
              title: 'Confirm User Deletion',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Deactivate',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.deactivateRecord()
            }
          })
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Inadequate Permission',
              icon: 'CheckCircleIcon',
              text: "You don't have enough rights to deactivate accounts in this section",
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    deactivateRecord() {
      const { id } = this.$store.state.auth.ActiveUser || {}

      if (id === this.params.id) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Operation Failed',
              icon: 'CheckCircleIcon',
              text: 'You cannot deactivate your own account.',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else {
        this.showDeactivateSuccess()

        // this.params.context.componentParent.get_company_users();
      }
    },
    showDeactivateSuccess() {
      const { token } = this.$store.state.auth.ActiveUser
      const { id } = this.params
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.isLoading = true

      this.$http
        .delete(`/api/users/${id}`, {})
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toastSuccess('Account Operations', 'Account deactivated successfully.')

            this.$emit('refresh-record', true)
            // this.params.context.componentParent.deleteSelected()
          } else {
            this.$toastDanger('Account Operations', 'Could not deactivate the user account please try again later')
          }

          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error)
          // this.$toast(
          //   {
          //     component: this.$toastContent,
          //     props: {
          //       title: 'Account Operations',
          //       icon: 'CheckCircleIcon',
          //       text: 'Could not deactivate the user account please try again later',
          //       variant: 'danger',
          //       position: 'center',
          //     },
          //   },
          //   { timeout: this.$longestTimeout },
          // )

          this.isLoading = false
        })
    },

    confirmReactivateRecord() {
      if (this.$checkRights(this.$route.meta.section, 'update')) {
        this.$bvModal
          .msgBoxConfirm(
            `Are you sure you want to reactivate "${this.params.email_address}"`,
            {
              title: 'Confirm User Restoration',
              size: 'sm',
              okVariant: 'success',
              okTitle: 'Reactivate',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.reactivateRecord()
            }
          })
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Inadequate Permission',
              icon: 'CheckCircleIcon',
              text: "You don't have enough rights to reactivate accounts in this section",
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    reactivateRecord() {
      const { id } = this.$store.state.auth.ActiveUser || {}

      if (id === this.params.id) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Operation Failed',
              icon: 'CheckCircleIcon',
              text: 'You cannot reactivate your own account.',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else {
        this.showReactivateSuccess()

        // this.params.context.componentParent.get_company_users();
      }
    },
    showReactivateSuccess() {
      const { token } = this.$store.state.auth.ActiveUser
      const { id } = this.params
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.isLoading = true

      this.$http
        .put(`/api/users/${id}/reactivate`, {})
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toastSuccess('Account Operations', 'Account reactivated successfully.')

            this.$emit('refresh-record', true)
            // this.params.context.componentParent.deleteSelected()
          } else {
            this.$toastDanger('Account Operations', 'Could not reactivate the user account please try again later')
          }

          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error)
          // this.$toast(
          //   {
          //     component: this.$toastContent,
          //     props: {
          //       title: 'Account Operations',
          //       icon: 'CheckCircleIcon',
          //       text: 'Could not deactivate the user account please try again later',
          //       variant: 'danger',
          //       position: 'center',
          //     },
          //   },
          //   { timeout: this.$longestTimeout },
          // )

          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.small-space {
  margin-left: 2px;
  margin-right: 2px;
}
</style>
